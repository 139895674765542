import clsx from 'clsx'
import { FC } from 'react'

import { TQuiz } from '../..'

import styles from './lang.module.scss'
import { backgroundQuiz_1 } from '@/Assets/img/quiz'
import { QuizContent } from '@/entities/quiz-content'
import { QuizLayout } from '@/features/quiz'
import { useLocalStorage } from '@/shared/hooks/useLocalStorage'
import { useAppDispatch } from '@/shared/model'
import { setLang } from '@/app/store/actions/defaultActions'
import { Button } from '@/shared/ui/form'

export const QuizLang: FC<TQuiz> = ({ onNextStep }) => {
	const dispatch = useAppDispatch()

	const { setLocalStorageValue } = useLocalStorage('locale')

	const onChangeLang = (lang: string) => {
		setLocalStorageValue(lang)
		dispatch(setLang(lang))

		document.documentElement.lang = lang
		onNextStep?.()
	}
	return (
		<QuizLayout background={backgroundQuiz_1}>
			<div
				style={{
					margin: '0 auto',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					alignItems: 'center',
					overflow: 'hidden'
				}}
			>
				<QuizContent
					title={''}
					maxWidth='717px'
					onNextStep={onNextStep}
					classNameWrapper={styles.wrapper}
					buttonsJSX={
						<>
							<Button modifiers={['gold']} onClick={() => onChangeLang('en')}>
								english
							</Button>
							<Button modifiers={['gold']} onClick={() => onChangeLang('cn')}>
								中文
							</Button>
						</>
					}
				/>

				<div className={styles.images} />
			</div>
		</QuizLayout>
	)
}
