import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

import { footerLinks } from './constants'
import styles from './footer-new.module.scss'
import {
	InstagramJSX,
	QQJSX,
	TelegramJSX,
	TwitterJSX,
	WeChatJSX,
	WeChat_QRJSX,
	WeIboJSX
} from '@/shared/assets/common/svg'
import { useTranslationField } from '@/shared/hooks'
import { TValuesLocality } from '@/shared/types/global.types'

export const Footer = () => {
	const { getFieldStatic } = useTranslationField()

	return (
		<div className={styles.wrapper}>
			<div className={styles.footerZone}>
				<div className={styles.infoBlock}>
					{footerLinks.map((item, index) => {
						return (
							<Fragment key={item.url}>
								<div className={styles.infoItem} key={item.url}>
									<Link to={item.url}>
										{getFieldStatic(item.name as TValuesLocality)}
									</Link>{' '}
								</div>
								{index !== footerLinks.length - 1 && <span>|</span>}
							</Fragment>
						)
					})}
				</div>
				<p className={styles.text}>{getFieldStatic('footer_text')}</p>
				<p className={styles.social}>
					<a href='https://weibo.com/u/5020382965'>
						<WeIboJSX />
					</a>
					<Tooltip className={styles.tooltipWrapp} id='my-tooltip-inline'>
						<div className={styles.tooltip}>
							<WeChat_QRJSX />
						</div>
					</Tooltip>
					<span data-tooltip-id='my-tooltip-inline'>
						<WeChatJSX />
					</span>

					<a href='https://qm.qq.com/q/typ1OeroS6'>
						<QQJSX />
					</a>
					<a href='https://t.me/lebaoba'>
						<TelegramJSX />
					</a>
					<a href=' https://www.instagram.com/lebao.ba?igsh=NWRma3ppYzc0bmxs&utm_source=qr'>
						<InstagramJSX />
					</a>
					<a href='https://x.com/lebaofun?s=21'>
						<TwitterJSX />
					</a>
				</p>
				<div className={styles.year}>
					{getFieldStatic('footer_label')} {new Date().getFullYear()}
				</div>
			</div>
		</div>
	)
}
