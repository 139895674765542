export const footerLinks = [
	{
		url: '/faq',
		name: 'faq_route'
	},
	{
		url: '/terms-and-conditions',
		name: 'terms-and-conditions_route'
	},
	{
		url: '/privacy-policy',
		name: 'privacy-policy_route'
	},

	// TODO Скрыто по дизайну
	// {
	// 	url: '/gamble-responsible',
	// 	name: 'gamble-responsible_route'
	// },
	// {
	// 	url: '/bet-dictionary',
	// 	name: 'bet-dictionary_route'
	// }
	{
		url: '/gamble-responsible',
		name: 'gamble-responsible_route'
	},
	{
		url: '/bet-dictionary',
		name: 'bet-dictionary_route'
	}
]