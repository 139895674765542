import { FC } from 'react'

import { TQuiz } from '../..'

import { backgroundQuiz_4, image_22, image_22_mobi } from '@/Assets/img/quiz'
import { QuizLayout } from '@/features/quiz'
import { useTranslationField } from '@/shared/hooks'
import { useIsMobile } from '@/shared/hooks'
import { QuizLayoutTwo } from '@/shared/ui/quiz-layout/quiz-layout-2'

export const QuizTwo: FC<TQuiz> = ({ onNextStep }) => {
	const { getFieldStatic } = useTranslationField()
	const { isMobile } = useIsMobile(768)

	return (
		<QuizLayout background={backgroundQuiz_4}>
			<QuizLayoutTwo
				image={isMobile ? image_22_mobi : image_22}
				onNextStep={onNextStep}
				title={getFieldStatic('questionTwoVariant3')}
			/>
		</QuizLayout>
	)
}
