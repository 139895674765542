import {
	urlBackend,
	urlBackend2,
	urlHtmlPerfix,
	urlWebSocketPerfix
} from '@/app/store/constants'

export const API_URL =
	process.env.REACT_APP_API_URL || urlHtmlPerfix + urlBackend2

const SOCKET_LOCAL = `ws://localhost:8000/chat/ws/`

export const API_WEB_SOCKET = `${API_URL.replace(
	urlHtmlPerfix,
	urlWebSocketPerfix
)}chat/ws/`

export const getAnalyticsUrl = (string: string) => `/analytics${string}`
export const getNewsUrl = (string: string) => `/news${string}`
export const getSchoolUrl = (string: string) => `/school${string}`
export const getShopUrl = (string: string) => `/shop${string}`
export const getUserUrl = (string: string) => `/user${string}`
export const getSportUrl = (string: string) => `/sport${string}`
export const getGameUrl = (string: string) => `/game/${string}`
export const getChatUrl = (string: string) => `/chat/${string}`
