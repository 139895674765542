import axios, { Axios, AxiosError, InternalAxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'

import { errorCatch } from '../helpers'

import { API_URL } from '@/shared/api/config/api.config'
import { removeTokensStorage } from '@/shared/services/auth/auth.helper'
import AuthService from '@/shared/services/auth/auth.service'
import { store } from '@/app/store/store'

const axiosInstance = axios.create({
	baseURL: API_URL
	// headers: getContentType()
})

const interceptorsRequestSuccess = (
	config: InternalAxiosRequestConfig<any>
) => {
	// TODO: change after new store
	const accessToken =
		localStorage.getItem('access') || Cookies.get('accessToken')
	if (config.headers && accessToken)
		config.headers.Authorization = `Bearer ${accessToken}`

	return config
}

axiosInstance.interceptors.request.use(interceptorsRequestSuccess)

const interceptorsResponseError = async (error: AxiosError<any>) => {
	const originalRequest = { ...error.config, _isRetry: false }
	//@ts-ignore
	const isPassword = error?.response?.data?.detail === 'Wrong password'

	if (
		(error.response?.status === 401 ||
			error.response?.status === 403 ||
			// TODO error messages
			errorCatch(error) === 'jwt expired' ||
			errorCatch(error) === 'jwt must be provided') &&
		error.config &&
		!originalRequest._isRetry &&
		!isPassword
	) {
		originalRequest._isRetry = true
		try {
			await AuthService.getNewTokens()

			return axiosInstance.request(originalRequest)
		} catch (error) {
			if (errorCatch(error) === 'jwt expired') {
				removeTokensStorage()
			}
		}
	}

	throw error
}

axiosInstance.interceptors.response.use(async config => {
	return config
}, interceptorsResponseError)

export default axiosInstance
