import clsx from 'clsx'
import { FC, PropsWithChildren } from 'react'

import styles from './Button.module.scss'

const mod = {
	gold: false,
	red: false,
	success: false,
	full: false,
	small: false,
	inverse: false,
	large: false
}

type TModifier = keyof typeof mod

type TProps = PropsWithChildren & {
	onClick?: () => void
	modifiers?: TModifier[]
	title?: string
	disabled?: boolean
	className?: string
	classNameContainer?: string
	type?: 'submit' | 'reset' | 'button'
}

export const Button: FC<TProps> = ({
	type = 'submit',
	children,
	onClick,
	modifiers = [],
	title,
	disabled,
	className,
	classNameContainer
}) => {
	const modList = { ...mod }

	modifiers.forEach(item => {
		modList[item] = true
	})

	return (
		<div className={clsx(styles.wrapper, classNameContainer)}>
			<button
				className={clsx(
					styles.button,
					className,
					disabled && styles.disabled,
					modList.success && styles.success,
					modList.gold && styles.gold,
					modList.red && styles.red,
					modList.small && styles.small,
					modList.full && styles.fullWidth,
					modList.inverse && styles.inverse,
					modList.large && styles.large
				)}
				data-content={title}
				onClick={onClick}
				disabled={disabled}
				type={type}
			>
				{children}
			</button>
		</div>
	)
}
