
import { typeBetDictionary } from './types'
import img_1 from '@/Assets/img/Header/img.webp'
import img_2 from '@/Assets/img/Header/img_2.webp'
import img_3 from '@/Assets/img/Header/img_2.webp'
import img_4 from '@/Assets/img/Header/img_3.webp'
import img_5 from '@/Assets/img/Header/img_4.webp'
import icon from '@/Assets/img/icon.webp'
import { LOCALES } from '@/i18n/locales'
import { profileIcon } from '@/shared/assets/common/svg'
import {
	experts,
	games,
	languageIcon,
	leaderboard,
	shop
} from '@/shared/assets/header/svg'
import { LinkItemMenuProps, ILanguages } from '@/shared/types/global.types'

export const urlBackend2: string = 'api.lebaoba.fun/'
export const urlBackend: string = 'api.lebao.fun/'
// export const urlBackend: string = 'localhost:1849/'
// export const urlBackend2: string = 'localhost:8000/'

// export const urlHtmlPerfix: string = 'http://'
export const urlHtmlPerfix: string = 'https://'
export const urlHtmlPerfix2: string = 'https://'
export const urlWebSocketPerfix: string = 'wss://'

export const urlWS: string = 'v2.lebao.fun/'
export const betDictionary: typeBetDictionary.Main = {
	en: [
		{ title: 'Accountant (dealer)', description: 'The nickname of "gambler".' },
		{
			title: 'Accumulator (multi-color)',
			description:
				'A type of bet, if the first bet is won, then the bonus won becomes the bet for the second bet; and so on (also known as “winning streak”, “single string” or “clearance”). All choices made (at least two) must be won in order for the bettor to win multiple prizes.\n' +
				'example：\n' +
				'Betting on the winning popular team in a game may only provide odds of 1.20 (1/5), which will not generate much profit. However, if you bet on the popular team in all three games through multiple draws, the gambler will be able to significantly increase his odds. Previously, you could only earn美金12 when betting on美金10 at odds of 1.20 (1/5), and you could win up to美金200 if you bet on multiple colors.\n'
		},
		{
			title: 'Action (bet)',
			description:
				'1) Any valid betting behavior. Different sports use different rules to determine whether the gambling is a bet or not (for example, baseball betting is called a bet before the game is played for 4.5 innings).\n 2) There is no requirement for bowlers to bet on previous baseball games.'
		},
		{
			title: 'All-In Betting (all-in gambling)',
			description:
				'One type of bet, if the competitor/team withdraws from the game, the bet will not be refunded. If the event is cancelled for any reason, the gambling amount will be refunded.\n'
		},
		{
			title: 'Arbitrage (Arbitrage)',
			description:
				'A gambling method in which gamblers place bets on both sides of the game at two or more bookmakers to ensure a “steady win”. Since everyone knows that each dealer has its own odds, it is possible to place a bet on one competitor/team at dealer A, and then place a bet on another competitor/team at dealer B (or even a draw at the third dealer).\n' +
				'example：\n' +
				'Dealer A offers odds of 2.05 for competitor/team A and 1.90 for competitor/team B. Dealer B offers odds of 1.90 for competitor/team A and 2.05 for competitor/team B. By betting 100 US dollars on the pair of competitors/team A in dealer A and 100 US dollars on the pair of competitors/team B in dealer B, regardless of the result of the game, the player can ensure a profit of 5 US dollars.'
		},
		{
			title: 'Asian Handicap (Asian Edition)',
			description:
				'Refers to a kind of spread gambling in football. The Asian market (also known as ”Hang Cheng") removes the possibility of a draw, thereby significantly increasing the chance of gamblers winning money. There are only two results in the Asian market, the "home team“ or the ”away team" wins, and part of the principal may be refunded in the event of a draw based on the handicap. The Asian set started with a virtual lead goal and was able to increase in increments of ¼. For example: ¼ ball, ½ ball, ¾ ball, 1 goal ball, 1¼ ball, etc.\n' +
				'example：\n' +
				'When the underdog gets a handicap of ½ goal, it means you bet on it to start with a virtual half goal. For example, Manchester United and Arsenal are playing (Arsenal has a half-goal handicap) and you bet on Manchester United to win. Depending on the final score, the result of the gamble is as follows：\n' +
				'Manchester Company vs. Arsenal：\n' +
				'0-0 = lose\n' +
				'1-0 = win\n' +
				'0-1 = lose\n' +
				'2-1= win\n' +
				'3-2 = win'
		},
		{ title: 'B.M.', description: 'The abbreviation of ”gambler".\n' },
		{ title: 'B.R.', description: 'The abbreviation of ”funds".' },
		{
			title: 'Bad Beat (upset)',
			description:
				'Accidentally lost bets, such as: in the last few seconds of the event, you bet on the opponent of the team to score points or the horse race you bet on loses by a “nose” gap after leading the whole race.\n'
		},
		{
			title: 'Beef',
			description:
				'Make a claim or dispute with the gambling operator about the result of the gambling.\n'
		},
		{
			title: 'Bet the Limit (maximum bet)',
			description:
				'Place a bet on the maximum amount limited by the dealer for a certain event/competition.\n'
		},
		{
			title: 'Bettor (gambler)',
			description: 'People who gamble (also known as “bettors” in the UK).\n'
		},
		{ title: 'Big Dime', description: 'A bet of 10,000 U.S. dollars.' },
		{ title: 'Big Nickel', description: 'A bet of 5,000 U.S. dollars.\n' },
		{
			title: 'Bookie (bettor)',
			description:
				'The person who accepts the bet. It is also known as a ”gambler".\n'
		},
		{
			title: 'Bookmaker (bookmaker)',
			description:
				'The person or company that accepts bets from the public is usually horse racing or sports activities (also known as “bettors” or “sports betting”).\n'
		},
		{ title: 'Bust (explosion)', description: 'Bankrupt players.' },
		{
			title: 'Chalk (popular)',
			description: 'Favored team, athlete or horse.'
		},
		{
			title: 'Chalk Eater (popular follower)',
			description:
				'Only players who bet on the top (also known as ”Chalk players").\n'
		},
		{
			title: 'Chalk Player (popular player)',
			description: 'Only for popular players (also known as “Chalk”).'
		},
		{
			title: 'Close/Closing Line (Close/End line)',
			description:
				'Also known as the final line, the closing/ending line refers to the betting line announced by the gambling owner before setting the odds for the event.\n'
		},
		{
			title: 'Correlated Parlay (related consecutive wins)',
			description:
				'If one bet is won, then it will increase the odds of another bet winning.\n' +
				'example：\n' +
				'If you win the first half of the bet and the second half of the bet, then obviously you will win the bet on the result of the whole game\n'
		},
		{ title: 'Degenerate (scum)', description: 'A morally corrupt gambler.\n' },
		{
			title: 'Dime',
			description: 'A Gambler 1,000 bet (also known as a ”dime bet").\n'
		},
		{
			title: 'Dog Player (underdog Player)',
			description: 'Gamblers who mainly bet on the underdog.\n'
		},
		{
			title: 'Dollar Bet',
			description:
				'A bet of 100 U.S. dollars (also known as "U.S. dollars”, "C-Note", "one U.S. dollar" or "one U.S. dollar”)\n'
		},
		{
			title: 'Double Chance',
			description:
				'This kind of betting allows you to win the same amount when the selected team wins or draws, which means that only a loss will cause you to lose the gamble.\n'
		},
		{
			title: 'Draw',
			description:
				'The two sides of the event finally shook hands and made peace (also known as ”Jerk“ or ”Push").\n'
		},
		{
			title: 'Draw No Bet',
			description:
				'If the game ends in a draw, then gamblers can withdraw their bets. This is particularly popular in gambling on football matches.\n'
		},
		{
			title: 'Early Money',
			description:
				'Bets placed immediately after the betting line is released to the public.\n'
		},
		{
			title: 'Earn (income)',
			description:
				'The total amount won by the gambler is divided by the total amount wagered (also known as the ”actual holding percentage").'
		},
		{
			title: 'European Handicap (European edition)',
			description:
				'Unlike the Asian market, there are no void bets or refunds in European gambling.\n'
		},
		{
			title: 'Favorite (popular)',
			description:
				'The competitor/team that is considered the most likely to win, so there are the smallest or lowest odds (also known as “jolly” or “sponk”).\n'
		},
		{
			title: 'First Half Bet',
			description: 'Bets made on the first half of the game.\n'
		},
		{
			title: 'Fixed Game (illegally manipulated game)',
			description:
				'In this game, one or more participants deliberately manipulated the final result of the game.\n'
		},
		{
			title: 'Fixed Odds',
			description:
				'Place a bet at pre-determined odds, which means you know the exact bonus you will get if you win when you place a bet. No matter how the odds will fluctuate after you place a bet, your potential earnings will not change.\n' +
				'example：\n' +
				'A gambler bet 100$  on the market at odds of 2.00 (general chance of winning), and a dealer accepted his bet. Before the game started, the odds on the same market fell to 1.70 (7/10); however, the gambler could still win 200$ if his bet won.\n'
		},
		{
			title: 'Futures',
			description:
				'Pre-released odds for the champions of various large-scale events, including the Super Bowl, the World Tour, the Stanley Cup, and the NBA championship game.\n'
		},
		{
			title: 'Gross Win (total revenue)',
			description: 'Consider the bonus before the fee.\n'
		},
		{
			title: 'Half Time Bet',
			description: 'A bet that only bets on the second half of the game.\n'
		},
		{
			title: 'Handicap',
			description:
				'A method used by bookmakers to make one-sided events a more attractive gambling pattern. The team was given some points as a "lead".\n'
		},
		{
			title: 'Handicapper (handicap expert)',
			description:
				'A person who studies factors such as data, injuries, weather, and news to predict the outcome of an event.'
		},
		{
			title: 'Handicapping',
			description: 'Trying to predict the outcome of a sporting event.'
		},
		{
			title: 'Handle (bet amount)',
			description:
				'The total amount wagered on a certain event or series of events.'
		},
		{
			title: 'Hold (percentage)',
			description: 'The percentage of casino wins.\n'
		},
		{
			title: 'Hook',
			description:
				'The gap of 0.5 points, such as 6.5, is the same as “6 and a hook”.\n'
		},
		{ title: 'House (casino)', description: 'Casino or gambling facility.\n' },
		{
			title: 'In-Play Betting (live betting)',
			description:
				'Live match betting, you can bet on the results or elements of the game that is still “in progress”.\n' +
				'example：\n' +
				'For football matches, you can bet on certain factors in the middle of the game, such as who will be given the next corner or which player will score the next goal. The odds change during the game, depending on the performance and position of the team and players.\n'
		},
		{
			title: 'Index Betting',
			description:
				"A type of gambling that allows bettors to predict the outcome of a game or game, and then make their decisions “close to” the gambler's handicap (also known as “spread gambling“).\n"
		},
		{
			title: 'Jerk',
			description: 'It also becomes a "draw”, "push" or "draw”'
		},
		{
			title: 'Limit',
			description:
				'The maximum amount that the dealer allows you to bet before changing the odds and/or score.\n'
		},
		{
			title: 'Line',
			description: 'The current odds or score difference of a certain event.\n'
		},
		{
			title: 'Linemaker (betting line maker)',
			description:
				'The person who sets the initial and subsequent betting lines (also known as the ”odds maker").\n'
		},
		{
			title: 'Listed Pitchers (Registered Pitchers)',
			description:
				'A baseball gamble that can only be bet if the pitchers of both sides who are expected to start the game do start the game. If they fail to actually start the game, the bet will be cancelled.\n'
		},
		{
			title: 'Long Odds (occasional chance)',
			description:
				'Odds such as 101.00 (100/1) are offered for those competitors who are unlikely to win.'
		},
		{
			title: 'Margin (difference)',
			description:
				'The amount of time one competitor completes the competition earlier than the other competitors.\n'
		},
		{
			title: 'Middle',
			description:
				'Place bets on both sides of the game at different prices in order to win two bets.\n' +
				'example：\n' +
				'If a gambler bets on Chicago (-4) and Los Angeles (+6), and Chicago wins 100-95, then the gambler will win two bets, or “caught in the middle.”\n'
		},
		{
			title: 'Money Line (win or lose)',
			description: 'Bets that do not include the difference in points.\n'
		},
		{
			title: 'Multiples (clearance)',
			description:
				'A type of betting, if the first bet is won, then the winnings won become the second bet; and so on (also known as “winning streak”, “single string” or “multiple winnings”). All choices made (at least two) must be won by the bettor in order to win the clearance bet.\n'
		},
		{
			title: 'Newspaper Line',
			description:
				'The odds line that appears in the daily newspaper. Please note that these odds lines are approximate, may be inaccurate and/or misleading.\n'
		},
		{
			title: 'No Action (non-betting)',
			description: 'A gamble that has neither lost nor won money.\n'
		},
		{
			title: 'Novelty Betting',
			description:
				'This includes betting on events that are not related to sports, such as popular TV shows (Big Brother and X Factor), betting on current events, and royal wedding features and presidential elections.\n'
		},
		{
			title: 'Odds maker',
			description:
				'People who set sports odds, but do not accept bets (also known as “odds compilers”).\n'
		},
		{
			title: 'Official Line (Official betting line)',
			description: 'A line used by the dealer for gambling purposes.\n'
		},
		{
			title: 'Outlaw Line (preliminary line)',
			description:
				'The early betting line is not the official line. The odds maker will invite selected gamblers to place bets on the “preliminary line” before disclosing the betting line to the public. Odds makers respect these experts and use their behavior to determine the final “opening line”. This process is called “ironing” or “flattening" of the thread.\n'
		},
		{
			title: 'Over/Under (Above/Below)',
			description:
				'Bets are made on whether the total score/number of goals scored by the two teams will exceed or be less than a certain number (also known as “Total”).\n'
		},
		{
			title: 'Parlay (single string)',
			description:
				'Also known as ”multi-lottery“, ”winning streak“ or ”clearance", this type of bet bets on the results of two or more teams, and the initial principal and bonus will be reinvested in the second bet. All choices made (at least two strokes) must be correct to win the single string. If there is a draw or the game is cancelled, the single string returns to the next number. (For example: a 4-team single string becomes a 3-team single string).\n'
		},
		{
			title: 'Picks (selected)',
			description: 'Expert betting options (also known as “tips").\n'
		},
		{
			title: 'Place (first two)',
			description:
				'1) A term used to describe the second-place finish.\n' +
				'2) Most commonly used in horse racing and dog running competitions, it refers to those horses or dogs that are in the top three, top four or sometimes top five in competitions or competitions.\n'
		},
		{ title: 'Player', description: 'Gambler or bettor.\n' },
		{
			title: 'Point Spread',
			description:
				'Most commonly used in basketball and rugby games, the point difference can be considered a handicap, pointing to a team (underdog) to provide a lead.\n' +
				'example：\n' +
				'Two NBA teams are playing, and one of them is very weak. In order to make gambling fairer, the dealer may require the popular team to win by at least 12 points to make the game fair. If the final score is a 12-point gap, then the gamble will be regarded as a “draw” and the principal will be refunded.'
		},
		{
			title: 'Pool (prize pool)',
			description:
				'The total amount of betting on win, place or show, or double betting for a game.\n'
		},
		{
			title: 'Power Ratings (strength Comparison)',
			description: 'The strength of one team compared to another.\n'
		},
		{ title: 'Price', description: 'The odds or line of the game.' },
		{
			title: 'Prop/Proposition Bet',
			description:
				'Make special bets on unique and many different activities, such as sporting events, politics, and test results. Betting uses a single-win format, which may include who scored the first touchdown in the Super Bowl, who will win the next presidential election, or whether someone on trial is finally convicted.'
		},
		{ title: 'Punter', description: 'The British name for gamblers.\n' },
		{
			title: 'Push',
			description:
				'In the end, the event/competition has no winners or losers from a gambling perspective (also known as ”Jerk“, ”draw“ or ”draw").'
		},
		{
			title: 'Risk (risk fund)',
			description: 'The amount of money wagered on a game or event.'
		},
		{
			title: 'Run Line',
			description:
				'The scoring difference used in baseball games is called instead of winning alone.\n'
		},
		{
			title: 'Scalper (Scalping Party)',
			description:
				'By betting on both sides of the same game at different prices, they try to profit from the difference in odds of different bookmakers (also known as “arbitrageurs”).\n'
		},
		{
			title: 'Scorecast Betting (score Prediction Betting)',
			description:
				'A type of multi-color gambling, gamblers need to correctly predict the player who scored the first goal in the first game and the correct result of the game to win the gamble.\n' +
				'example：\n' +
				'A gambler placed a分数10 score prediction bet at odds of 25.00 (24/1), betting that Messi scored the first goal and Barcelona won 3-0. If the Argentine does score the first goal and Barcelona wins 3-0, then the gambler can win美金250. If Messi scores the first goal, but Barcelona eventually wins by a score other than 3-0, or Barcelona wins 3-0 but someone else scores the first goal, then the gamble is lost.\n'
		},
		{
			title: 'Scratch (withdrawal)',
			description:
				'1) The competitor withdraws from the competition.\n' +
				'2) Withdraw, cancel or stop a gamble.'
		},
		{
			title: 'Show (top three)',
			description: 'A term used to describe the third-place finish.'
		},
		{
			title: 'Single/Single Bet',
			description:
				'”Straightforwardly" bet on a choice to win a game or event.\n'
		},
		{
			title: 'Sportsbook (sports Betting)',
			description:
				'A person or company that accepts bets (also known as a ”gambler").'
		},
		{
			title: 'Spread (difference)',
			description: 'The abbreviation form of ”score difference".\n'
		},
		{
			title: 'Spreads (points difference)',
			description: 'It is also called handicap.'
		},
		{
			title: 'Stake (principal)',
			description: 'A term for the amount of money wagered on an activity.\n'
		},
		{
			title: 'Straight/Straight Bet (Straight/Direct Bet)',
			description: 'Only bet on one team, one athlete, or one horse.\n'
		},
		{
			title: 'Sure Thing',
			description: 'There is only a small chance of losing the bet.\n'
		},
		{
			title: 'Taking the Points',
			description:
				'Bet on the underdog and its lead in the points difference.\n'
		},
		{
			title: 'Taking/Taking the Odds (betting on small probability)',
			description: 'Bet on the underdog.\n'
		},
		{
			title: 'Ticket',
			description:
				'Bets on gambling on sporting events (also known as ”bet").\n'
		},
		{
			title: 'Tips',
			description: 'Expert betting choices (also known as “Picks").\n'
		},
		{
			title: 'Tipster (informant)',
			description:
				'A person who provides or sells to a gambler his prediction of the possible winner of a game, game, or event (also known as “Tout”).\n'
		},
		{
			title: 'Total (total amount)',
			description:
				'Bets are made on whether the total score/number of goals scored by the two teams will exceed or be less than a certain number (also known as “above/below”).\n'
		},
		{
			title: 'Tout (Scout)',
			description:
				'A person who provides or sells gambling advice to gamblers (also known as an “informant”).\n'
		},
		{
			title: 'Tout Service (investigation Service)',
			description: 'A business that provides or sells gambling advice.\n'
		},
		{
			title: 'Triple (top three)',
			description:
				'Generally used in horse racing and dog racing, it refers to the gambler who correctly selects the first, second and third players to complete the game in an accurate order (also known as “Trifecta” or “Tricast”).\n'
		},
		{
			title: 'Two and Three Balls Betting (two-ball and three-ball betting)',
			description:
				'Gambling in golf includes predicting from a group of two or three which gambler will get the lowest score in an 18-ball game.'
		},
		{
			title: 'Under (below)',
			description:
				'Gamblers predict that the total score of the two teams will be lower than a certain score of bets.\n'
		},
		{
			title: 'Underdog',
			description:
				'The team that is considered most likely to lose (also known as “Alpo”, “Bow-Wow”, “Dog”, “Puppy” or “The Short”).'
		},
		{
			title: 'Value/Value Bets (Value Gambling):',
			description:
				'Also known as “Overlay", value bets are those bets that should give gamblers the greatest chance of profit. Many gamblers try to find games where they think the dealer made a mistake in setting the odds and bet on these games accordingly, rather than simply supporting popular teams.\n'
		},
		{
			title: 'Void Bet',
			description:
				"It is most common in Asian markets, and void bets mean that the gambler's principal will be returned. Another situation is when a gambler bets on a player to score the first goal, and he fails to even enter the starting lineup or when the bet is cancelled.\n"
		},
		{
			title: 'Wager (bet)',
			description:
				'Bet money on the result of an activity (also known as a “bet”).\n'
		},
		{
			title: 'X',
			description:
				'The "draws” in the game odds are such as those related to football and ice hockey.'
		},
		{ title: 'Zebras', description: 'Referee (also known as ”uniform").\n' }
	],
	cn: [
		{ title: 'Accountant（庄家)', description: '“赌博业者”的昵称。' },
		{
			title: 'Accumulator（多重彩）',
			description:
				'一种类型的投注，如果第一笔赌注获胜了，那么赢得的奖金成为了第二次投注的赌注；以此类推（也被称为“连赢”，“单串” 或 “过关”）。所有做出的选择（至少两笔）都必须获胜投注者才能赢得多重彩。例子, 一场比赛中对获胜热门队伍下注可能仅提供1.20的赔率（1/5），这并不会产生多少收益。然而，如果通过多重彩在三场比赛中都对热门队伍下注的话，赌博者就能够显著增加他的赔率。之前以1.20（1/5）的赔率投注10美金时只能挣得12美金，以多重彩下注则可能赢得最多达200美金。\n'
		},
		{
			title: 'Action（下注）',
			description:
				'1）任何有效的投注行为。不同的体育运动采用不同的规则来决定该赌博是下注抑或不是下注（比如：棒球投注在游戏进行4.5局之前被称为下注）。\n' +
				'2）没有规定投球手之前的棒球比赛下注。'
		},
		{
			title: 'All-In Betting（全押赌博）',
			description:
				'一种类型的投注，如果竞赛者/队伍退赛的话赌注不予以退还。如果赛事因故被取消，那么赌博金额会被退还。'
		},
		{
			title: 'Arbitrage（套利）',
			description:
				'一种赌博方法，赌博者通过在两家或更多庄家那对比赛双方进行下注以确保“稳赢”。由于大家都知道每一个庄家都有各自的赔率，所以有可能在庄家A那对一个竞赛者/队伍下注，然后在庄家B那对另一个竞赛者/队伍下注（甚至是在第三个庄家那下注平局）。例子, 庄家A为竞赛者/队伍A开出2.05的赔率，为竞赛者/队伍B开出1.90的赔率。而庄家B为竞赛者/队伍A开出1.90的赔率，为竞赛者/队伍B开出2.05的赔率。通过在庄家A那对竞赛者/队伍A押100美金，在庄家B那对竞赛者/队伍B押100美金，无论比赛结果如何玩家可以确保获得5美元的利润。\n'
		},
		{
			title: 'Asian Handicap（亚洲盘）',
			description:
				'指足球中的一种点差赌博。亚洲盘(也被称为“Hang Cheng”）移除了平局的可能性从而显著提高了赌博者赢钱的机会。亚洲盘里只有两种结果，“主队”或“客队”获胜，根据让分盘平局时也可能会有部分本金退还。亚洲盘以虚拟领先进球开始，能够以¼的增量增加。比如：¼ 球、 ½ 球、 ¾ 球、1 goal球、1¼ 球等。例子, 当弱旅获得½球的让分，这意味着您赌它以虚拟的半个进球开场。比如说曼切斯特联队和阿森纳正在比赛（阿森纳有½球让分）而您赌曼彻斯特联队获胜。取决于最终比分，赌博的结果如下：\n' +
				'曼切斯特连队 vs 阿森纳 0-0 = 输\n' +
				'1-0 = 赢\n' +
				'0-1 = 输\n' +
				'2-1= 赢\n' +
				'3-2 = 赢\n'
		},
		{ title: 'B.M.(赌博业者)', description: '“赌博业者”的缩写。' },
		{ title: 'B.R.(资金)', description: '“资金”的缩写。\n' },
		{
			title: 'Bad Beat（爆冷门）',
			description:
				'意外输掉的赌注，如：在赛事的最后几秒内您下注队伍的对手取得分数或是您下注的赛马在领先全程后以“一鼻”的差距输掉。\n'
		},
		{
			title: 'Beef(索赔)',
			description: '与赌博业者就赌博结果进行索赔或争议。\n'
		},
		{
			title: 'Bet the Limit（最高额下注）',
			description: '以庄家对某个赛事/比赛限定的最高额度下注。\n'
		},
		{
			title: 'Bettor（赌博者）',
			description: '赌博的人（在英国也被称为“下赌注者”）。\n'
		},
		{
			title: 'Big Dime(10，000美金赌注)',
			description: '一笔10，000美金的赌注。'
		},
		{
			title: 'Big Nickel(5，000美金赌注)',
			description: '一笔5，000美金的赌注。'
		},
		{
			title: 'Bookie（赌注者）',
			description: '接受赌注的人。也被称为“赌博业者”。\n'
		},
		{
			title: 'Bookmaker（赌博业者）',
			description:
				'从公众那接受赌注的人或公司，通常是赛马或体育活动（也被称为“赌注者”或“体育博彩”）。'
		},
		{ title: 'Bust（爆炸）', description: '破产的玩家。' },
		{ title: 'Chalk（热门）', description: '被看好的队伍、运动员或马匹。\n' },
		{
			title: 'Chalk Eater（热门追捧者）',
			description: '仅对热门下注的玩家（也被称为“Chalk玩家”）。\n'
		},
		{
			title: 'Chalk Player（热门玩家）',
			description: '仅对热门下注的玩家（也被称为“Chalk”）。'
		},
		{
			title: 'Close/Closing Line（关闭/结束线）',
			description:
				'也被称为最终线，关闭/结束线指赌博业主在为赛事设定赔率之前公布的投注线。'
		},
		{
			title: 'Correlated Parlay（相关连赢）',
			description:
				'如果一笔赌注赢了，那么它将增加另一笔赌注获胜的赔率。例子, 如果您赢了前半场的赌注和后半场的赌注，那么很显然您将会赢得全场比赛结果的赌注'
		},
		{ title: 'Degenerate（败类）', description: '道德败坏的赌徒。' },
		{
			title: 'Dime(角钱赌注)',
			description: '1，000美元的赌注（也被称为“角钱赌注”）。\n'
		},
		{
			title: 'Dog Player（弱旅玩家）',
			description: '主要对弱旅下注的赌博者。\n'
		},
		{
			title: 'Dollar Bet(100美元赌注)',
			description:
				'100美元的赌注（也被称为“美元”、“C-Note”、“富兰克林”或“一美元”）\n'
		},
		{
			title: 'Double Chance（双重机会）',
			description:
				'这种投注让您能够在所选队伍获胜或平局的时候赢得同样金额，这意味着只有告负会导致您输掉赌博。\n'
		},
		{
			title: 'Draw（平局）',
			description: '赛事双方最终握手言和（也被称为“Jerk”或“Push”）。'
		},
		{
			title: 'Draw No Bet（和局不赌）',
			description:
				'如果游戏最终以平局结束，那么赌博者可以收回他们的赌注。这在足球赛事赌博中特别受欢迎。\n'
		},
		{
			title: 'Early Money（早钱）',
			description: '在投注线向公众发布之后立即就下的赌注。'
		},
		{
			title: 'Earn（收益）',
			description:
				'赌博业者赢得的总金额除以下注的总金额（也被称为“实际持有百分比”）。\n'
		},
		{
			title: 'European Handicap（欧洲盘）',
			description: '与亚洲盘不同，在欧洲盘赌博里没有作废投注或是赌注退还。\n'
		},
		{
			title: 'Favorite（热门）',
			description:
				'被认为最有可能获胜的竞赛者/队伍，因此有最小或最低的赔率（也被称为“jolly”或“sponk”）。\n'
		},
		{
			title: 'First Half Bet（前半场投注）',
			description: '对比赛前半场进行的下注。\n'
		},
		{
			title: 'Fixed Game（非法操纵的比赛）',
			description: '在这场比赛中一名或多名参赛者有意操控了比赛的最终结果。'
		},
		{
			title: 'Fixed Odds（固定赔率）',
			description:
				'以事先决定的赔率下注，这意味着您知道在下注时就知道如若获胜将可以获得的确切奖金。不论在您下注之后赔率会如何浮动，您的潜在收益不会变化。例子, 一名赌徒以2.00（一般获胜几率）的赔率在市场上下注100美金，一名庄家接受了他的投注。在游戏开始前，同一市场上的赔率下降到了1.70（7/10）；然而该赌徒仍然可以赢得200美金如果他的赌注获胜。'
		},
		{
			title: 'Futures（期货）',
			description:
				'对于各种大型活动的冠军事先发布的赔率，包括超级碗、世界巡回赛、斯坦利杯以及NBA冠军赛。'
		},
		{ title: 'Gross Win（总收益）', description: '考虑费用之前的奖金。' },
		{
			title: 'Half Time Bet（半场投注）',
			description: '仅对游戏的下半场进行投注的赌注。\n'
		},
		{
			title: 'Handicap（盘口）',
			description:
				'一种庄家用来使一边倒的赛事成为更有吸引力的赌博格局的方法。队伍被寄予一些分数作为“领先优势”。'
		},
		{
			title: 'Handicapper（让分专家）',
			description: '研究诸如数据、伤病、天气和新闻等因素以预测赛事结果的人。\n'
		},
		{ title: 'Handicapping（让分）', description: '试图预测体育赛事的结果。' },
		{
			title: 'Handle（下注金额）',
			description: '对某一赛事或一系列赛事下注的总金额。\n'
		},
		{ title: 'Hold（百分比）', description: '赌场获胜的百分比。' },
		{
			title: 'Hook(0.5分的差距)',
			description: '0.5分的差距比如6.5，与“6和一个hook”一样。'
		},
		{ title: 'House（赌场）', description: '赌场或赌博设施。' },
		{
			title: 'In-Play Betting（滚球投注）',
			description:
				"直播比赛投注，可以对仍然在“进行中”的比赛的结果或元素进行投注。例子, 对于足球比赛，可以在比赛中间对某些因素下注，比如谁会被给予下一个角球或是哪个球员会进下一个球。赔率在比赛过程中变化，取决于队伍和球员的表现和位置。'"
		},
		{
			title: 'Index Betting（指数投注）',
			description:
				'一种类型的赌博，允许投注者预测游戏或比赛的结果，然后使他们的决策“”紧靠着“赌博业者的让分（也被称为“差价赌博”）。'
		},
		{ title: 'Jerk(平局)', description: '也成为“平局”，“Push”或“和局”' },
		{
			title: 'Limit（限额）',
			description: '庄家在改变赔率以及/或分数之前允许你下注的最高限额。\n'
		},
		{ title: 'Line（线）', description: '某项赛事的当前赔率或分差。' },
		{
			title: 'Linemaker（投注线制定者）',
			description: '设定最初和之后投注线的人（也被称为“赔率制定者”）。\n'
		},
		{
			title: 'Listed Pitchers（报名投手）',
			description:
				'仅当预计开始比赛的双方投手确实开始比赛才能下注的棒球赌博。如果他们未能确实开始比赛，投注将被取消。\n'
		},
		{
			title: 'Long Odds（偶尔机会）',
			description: '为那些不可能获胜的竞赛者开出如101.00（100/1）之类的赔率。\n'
		},
		{
			title: 'Margin（差额）',
			description: '一位竞赛者早于其他竞赛者完成比赛的量。\n'
		},
		{
			title: 'Middle（中间）',
			description: '以不同价格对比赛双方都下注以期赢得两笔赌注。'
		},
		{ title: 'Money Line（输赢盘）', description: '不包含分差的赌注。\n' },
		{
			title: 'Multiples（过关）',
			description:
				'一种类型的投注，如果第一笔赌注获胜了，那么赢得的奖金成为了第二次投注的赌注；以此类推（也被称为“连赢”，“单串” 或 “多重彩”）。所有做出的选择（至少两笔）都必须获胜投注者才能赢得过关赌注。'
		},
		{
			title: 'Newspaper Line（新闻报纸线）',
			description:
				'出现在每日报纸上的赔率线。请注意这些赔率线是近似的，可能不精确以及/或是误导性的。'
		},
		{
			title: 'No Action（非下注）',
			description: '一笔没有输钱也没有赢钱的赌博。'
		},
		{
			title: 'Novelty Betting（新奇投注）',
			description:
				'包括对那些与体育运动毫无关联的赛事进行投注，比如热门的电视节目（Big Brother和X Factor），对时事进行投注以及皇家婚礼特色和总统选举等。\n'
		},
		{
			title: 'Oddsmaker（赔率制定者）',
			description:
				'制定体育运动赔率的人，但是不接受下注（也被称为“赔率编纂者”）。'
		},
		{
			title: 'Official Line（官方投注线）',
			description: '庄家用于赌博用途的线。'
		},
		{
			title: 'Outlaw Line（初步线）',
			description:
				'早期投注线，不是官方线。赔率制定者会邀请特选的赌博者在向公众公开投注线之前对“初步线”下注。赔率制定者尊重这些专家并使用他们的行为来决定最终的“开场线”。这一过程被称为对线的“熨烫”或“压扁”。'
		},
		{
			title: 'Over/Under（之上/之下）',
			description:
				'对于由两支队伍获得的总分/进球数是否会超过或少于某个特定数字进行投注（也被称为“Total”）。'
		},
		{
			title: 'Parlay（单串）',
			description:
				'也被称为“多重彩”，“连赢” 或 “过关”，这种类型的投注对两个或更多队伍或是结果进行下注，最初的本金和奖金将重新投资于第二笔赌注。所有做出的选择（至少两笔）都必须正确才能赢得单串。如果平局或是游戏被取消，单串就返回下一个数字。（比如：一个4队单串成为一个3队单串）。'
		},
		{ title: 'Picks（精选）', description: '专家的投注选择（也称为“tips“）。' },
		{
			title: 'Place（头两名）',
			description:
				'1）用于描述第二名跑完的术语。\n' +
				'2）最常用于赛马和跑狗比赛，指那些在竞赛或比赛中位居前三、前四或有时前五的马匹或狗。'
		},
		{ title: 'Player（玩家）', description: '赌博者或下赌注者。' },
		{
			title: 'Point Spread（分差）',
			description:
				'最常见于篮球和橄榄球比赛，分差可以被认为是一种让盘，指向一支队伍（弱旅）提供领先优势, 例子, 两支NBA队伍正在比赛，其中一支实力差的非常多。为了让赌博更为公平，庄家可能要求热门队伍以至少12分的优势获胜使游戏公平。如果最终得分是12分差距，那么赌博将被视作“平局”而本金将被退还。'
		},
		{
			title: 'Pool（奖池）',
			description: '为一场比赛投注win、place或show、或是双赌法的总金额。\n'
		},
		{
			title: 'Power Ratings（实力比对）',
			description: '一支队伍相比另一支队伍的实力。\n'
		},
		{ title: 'Price（价格）', description: '游戏的赔率或线。' },
		{
			title: 'Prop/Proposition Bet（提议赌博）',
			description:
				'对独特的、多种不同的活动进行特殊的投注，比如体育赛事、政治和试验结果。投注使用独赢的格式，可能包括谁在超级碗中第一个达阵，谁会赢得下一届总统选举或是被审判的某人是否最后被判定有罪。'
		},
		{ title: 'Punter（下注者）', description: '英国对赌博者的叫法。' },
		{
			title: 'Push(活动)',
			description:
				'活动/比赛最终以赌博角度看没有赢家或输家（也被称为“Jerk”、“平局”或“和局”）。'
		},
		{ title: 'Risk（风险金）', description: '对一场游戏或活动下注的金额。\n' },
		{
			title: 'Run Line（奔跑线）',
			description: '在棒球赛中使用的分差叫法而不是独赢。\n'
		},
		{
			title: 'Scalper（倒卖党）',
			description:
				'通过在对同一场比赛的双方以不同价格下注，试图从不同庄家的赔率差别中盈利（也被称为“套利者”）。\n'
		},
		{
			title: 'Scorecast Betting（分数预测赌注）',
			description:
				'一种类型的多重彩赌博，赌博者需要正确预测在第一场比赛中进第一个球的球员以及该场比赛的正确比赛结果以赢得赌博。例子, 一名赌博者以25.00（24/1）的赔率下了10美金的分数预测赌注，赌梅西进第一个球并且巴塞罗那队以3-0获胜。如果这名阿根廷人确实进了第一个球且巴塞罗那以3-0获胜，那么赌博者就能赢的250美金。如果梅西进来第一个球，但是巴塞罗那队最终以3-0以外的比分获胜或是巴塞罗那队以3-0获胜但是其他人进了第一个球，那么这笔赌博就输了。\n'
		},
		{
			title: 'Scratch（退赛）',
			description: '1）竞赛者退赛。\n' + '2）撤回、取消或停止一笔赌博。\n'
		},
		{ title: 'Show（头三名）', description: '用来描述第三名跑完的术语。\n' },
		{
			title: 'Single/Single Bet（单/单式投注）',
			description: '“直截了当”地对赢得比赛或活动的一个选择投注。\n'
		},
		{
			title: 'Sportsbook（体育博彩）',
			description: '接受赌注的人或公司（也被称为“赌博业者”）。\n'
		},
		{ title: 'Spread（差）', description: '“分差”的缩写形式。\n' },
		{ title: 'Spreads（分差）', description: '也被称为让盘。\n' },
		{ title: 'Stake（本金）', description: '对一项活动下注的资金术语。' },
		{
			title: 'Straight/Straight Bet（直/直接投注）',
			description: '仅对一支队伍、一名运动员或一匹马投注。\n'
		},
		{
			title: 'Sure Thing（确定的事）',
			description: '只有很小几率输掉的赌注。'
		},
		{
			title: 'Taking the Points（拿分）',
			description: '对弱旅以及其在分差上的领先投注。'
		},
		{
			title: 'Taking/Taking the Odds（赌小概率）',
			description: '对弱旅投注。'
		},
		{
			title: 'Ticket（票）',
			description: '体育赛事赌博的赌注（也被称为“bet”）。\n'
		},
		{
			title: 'Tips（窍门）',
			description: '专家的投注选择（也称为“Picks“）。\n'
		},
		{
			title: 'Tipster（线人）',
			description:
				'向赌博者提供或出售他对比赛、游戏或活动可能获胜者的预测的人（也被称为“Tout”）。\n'
		},
		{
			title: 'Total（总额）',
			description:
				'对于由两支队伍获得的总分/进球数是否会超过或少于某个特定数字进行投注（也被称为“以上/以下”）。\n'
		},
		{
			title: 'Tout（侦查者）',
			description: '向赌博者提供或出售赌博建议的人（也被称为“线人”）。\n'
		},
		{
			title: 'Tout Service（侦查服务）',
			description: '提供或出售赌博建议的业务。\n'
		},
		{
			title: 'Triple（头三名）',
			description:
				'一般用于赛马和跑狗比赛，指赌博者以准确的顺序正确选择了第一、第二和第三名完成比赛的选手（也被称为“Trifecta”或“Tricast”）。'
		},
		{
			title: 'Two and Three Balls Betting（两球和三球投注）',
			description:
				'高尔夫球的赌博，包括从两人组或三人组中预测哪一个赌博者会在18球比赛中取得最低分。'
		},
		{
			title: 'Under（以下）',
			description: '赌博者预测两支队伍取得的总分会低于某个分数的投注。'
		},
		{
			title: 'Underdog（弱旅）',
			description:
				'被认为最有可能输的队伍（也被称为“Alpo”, “Bow-Wow”, “Dog”, “Puppy” 或 “The Short”)。\n'
		},
		{
			title: 'Value/Value Bets（价值赌博）',
			description:
				'也被称为“Overlay“，value bets是那些应当给赌博者最大盈利几率的赌注。许多赌博者试图找出他们认为庄家在制定赔率时犯了错误的比赛并且相应地对这些比赛下注，而不是单纯地支持热门队伍。'
		},
		{
			title: 'Void Bet（作废投注）',
			description:
				'在亚洲盘中最为常见，作废投注意味着赌博者的本金会被归还。另一种情况是当赌博者赌某个玩家进第一个球，而他甚至没能进入首发阵容或是当下注的比赛被取消。'
		},
		{
			title: 'Wager（下注）',
			description: '对一项活动的结果赌钱（也被称为“赌注”）。\n'
		},
		{
			title: 'X(赔率中的“平局”)',
			description: '比赛赔率中的“平局”比如那些与足球和冰球有关的结果。\n'
		},
		{ title: 'Zebras（斑马）', description: '裁判（也被称为“制服”）。\n' }
	]
}
export const FAQ: typeBetDictionary.Main = {
	en: [
		{
			title: 'How do I get in a loyalty program?',
			description:
				'You can join the loyalty program as soon as you sign up, and you can earn experience points (XP) for each activity, which you can upgrade to new levels as you gain experience. You can find different levels of descriptions in your personal account.'
		},
		{
			title: 'Why should I play online?',
			description:
				'Online games will bring you a more convenient and safer gaming experience.'
		},
		{
			title: 'Is making a deposit safe?',
			description:
				'Of course, all payment systems that we offer you are safe!\n' +
				'If you have any difficulties, you can contact the customer service system at any time, and we will help you solve the problems you encounter.'
		},
		{
			title: 'What is a welcome bonus?',
			description:
				'The welcome bonus is credited to your account as soon as you sign up.\n' +
				'You can also get extra bonuses by installing the LeBao app!'
		},
		{
			title: 'How can I use my betcoins?',
			description:
				"'You can use BetCoins to bet, play games and exchange BetCoins for merchandise in the store!"
		},
		{ title: 'What is the minimum deposit?', description: '100 yuan' },
		{
			title: 'Are there any game restrictions if I play with a bonus?',
			description:
				'There are no restrictions, you can manage and accumulate winnings just like regular betting coins in your account.'
		},
		{
			title: 'Who should I contact if I have a problems?',
			description:
				'You can contact our customer service system, our customer service staff will help you anytime and anywhere, or contact us by email: team@lebaoba.fun'
		}
	],
	cn: [
		{
			title: '如何加入忠诚度计划？',
			description:
				'您在注册后可立即加入忠诚计划，每个活动您都可以获得经验值（XP），并随着经验值得积累升级到新的级别。 您可以在个人帐户中找到不同级别的描述。'
		},
		{
			title: '为什么选择线上游戏？',
			description: '线上游戏给您带来更便捷且更安全的游戏体验。'
		},
		{
			title: '存款安全吗？',
			description:
				'当然,我们为您提供的所有的支付系统都是安全的！\n' +
				'如果您有任何困难，可以随时联系客服系统，我们将帮助您解决您遇到的的问题。'
		},
		{
			title: '什么是迎新奖金？',
			description:
				'迎新奖金在您注册后立即计入您的账户。\n' +
				'您也可以通过安装乐宝app来获得额外的奖金！'
		},
		{
			title: '如何使用我的投注币？',
			description:
				'您可以利用投注币投注，玩游戏，还可以在商店用投注币兑换商品！'
		},
		{ title: '最低存款是多少？', description: '100 元' },
		{
			title: '如果我使用奖金玩游戏，是否有游戏限制？',
			description:
				'没有任何限制，您可以像管理帐户中的常规投注币一样管理和累积奖金。'
		},
		{
			title: '当遇到问题时我该联系谁？',
			description:
				'您可以联系我们的客服系统，我们的客服人员将随时随地为您提供帮助，或通过邮件给与我们联系：qwe@asd.zxc'
		}
	]
}
export const languages: Array<ILanguages> = [
	{ name: 'En', code: LOCALES.ENGLISH },
	{ name: '中文', code: LOCALES.CHINESE }
]
export const levelUserInfo: number[] = [
	0, 1000, 3000, 5000, 8000, 10000, 15000, 20000, 25000, 30000, 35000, 45000,
	50000
]
export const dataTab: Array<LinkItemMenuProps> = [
	{
		itemMenu: {
			itemName: 'header_menu_sports',
			image: null,
			icon: img_1,
			active: false,
			eventsNumber: 0,
			subMenu: [
				{
					itemMenu: {
						itemName: 'header_sports_sub_menu_match_centre',
						image: null,
						active: false,
						eventsNumber: 0
					},
					link: `/sports/match-centre`
				}
			]
		},
		link: '/sports'
	},

	{
		itemMenu: {
			itemName: 'header_menu_game',
			image: null,
			icon: games,
			active: false,
			eventsNumber: 0
		},
		link: `/games`
	},

	{
		itemMenu: {
			itemName: 'header_menu_expert',
			image: null,
			icon: experts,
			active: false,
			eventsNumber: 0
		},
		link: `/experts`
	},

	{
		itemMenu: {
			itemName: 'header_menu_Blog',
			image: null,
			icon: img_3,
			active: false,
			eventsNumber: 0,
			subMenu: [
				{
					itemMenu: {
						itemName: 'header_sports_sub_menu_blog_school',
						image: null,
						active: false,
						eventsNumber: 0
					},
					link: `/blog/school`
				},
				{
					itemMenu: {
						itemName: 'header_sports_sub_menu_blog_news',
						image: null,
						active: false,
						eventsNumber: 0
					},
					link: `/blog/news`
				}
			]
		},
		link: `/blog`
	},
	{
		itemMenu: {
			itemName: 'header_menu_shop',
			image: null,
			icon: shop,
			active: false,
			eventsNumber: 0
		},
		link: `/shop`
	},
	{
		itemMenu: {
			itemName: 'header_menu_leaderboard',
			image: null,
			icon: leaderboard,
			active: false,
			eventsNumber: 0
		},
		link: `/leaderboard`
	},
	{
		itemMenu: {
			itemName: 'header_menu_languages',
			image: null,
			icon: languageIcon,
			active: false,
			eventsNumber: 0
		},
		link: `/lang`
	},
	{
		itemMenu: {
			itemName: 'unkssnown',
			image: profileIcon,
			active: false,
			eventsNumber: 0,
			price: 0,
			subMenu: [
				{
					itemMenu: {
						itemName: 'profile_route',
						image: null,
						active: false,
						eventsNumber: 0
					},
					link: '/profile?tab=account-profile'
				},
				{
					itemMenu: {
						itemName: 'edit_data',
						image: null,
						active: false,
						eventsNumber: 0
					},
					link: `/profile?tab=edit-info`
				},
				{
					itemMenu: {
						itemName: 'payment_history',
						image: null,
						active: false,
						eventsNumber: 0
					},
					link: `/profile?tab=payment-history`
				},
				{
					itemMenu: {
						itemName: 'betting_history',
						image: null,
						active: false,
						eventsNumber: 0
					},
					link: `/profile?tab=betting-history`
				},
				{
					itemMenu: {
						itemName: 'deposit_history',
						image: null,
						active: false,
						eventsNumber: 0
					},
					link: `/profile?tab=deposit-history`
				},
				{
					itemMenu: {
						itemName: 'logout',
						image: null,
						active: false,
						eventsNumber: 0
					},
					link: `/profile/logout`
				}
			]
		},
		link: '/profile'
	}
]
