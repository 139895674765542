import clsx from 'clsx'
import React, {
	FC,
	PropsWithChildren,
	Suspense,
	useEffect,
	useRef,
	useState
} from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'

import Breadcrumbs from '../breadcrumbs'
import { BreadcrumbsExtra } from '../breadcrumbs/BreadcrumbsExtra'
import ScrollButton from '../buttons/scroll-top'
import { Loader } from '../loader'

import styles from './Layout.module.scss'
import { messages } from '@/i18n/messages'
import { typePage } from '@/pages/main/types'
import { useQueryParams } from '@/shared/hooks'
import { useBreadCrumbs } from '@/shared/hooks/useBreadCrumbs'
import { useIsMobile } from '@/shared/hooks'
import { usePageViews } from '@/shared/hooks/usePageViews'
import { useAppSelector } from '@/shared/model'
import { dataTab } from '@/app/store/constants'
import { LinkItemMenuProps } from '@/shared/types/global.types'
import { Chat } from '@/widgets/сhat'
import { Footer } from '@/widgets/footer'

const Header = React.lazy(() => import('@/widgets/header'))
// const Footer = React.lazy(() => import('@/widgets/footer'))

type TLayout = typePage & PropsWithChildren

const Layout: FC<TLayout> = ({
	children,
	titlePage,
	descriptionMeta,
	position,
	head_block,
	ad_block,
	fullWidth,
	style,
	fullComponent,
	hiddenBreadcrumbs,
	hiddenBreadcrumbsAll,
	extraMenuBlock,
	modifers,
	isCenterBreadcrumbs,
	footerHidden,
	replaceRouteName,
	fullHeight,
	hiddenQueryTab,
	goBackLink,
	titlePosition = 'center',
	menuName,
	customBreadcrumbs
}) => {
	const mod = {
		article: false,
		container: false,
		fullHeight: false,
		slots: false,
		fullWidth: false
	}

	modifers?.forEach(item => {
		mod[item] = true
	})

	const ref = useRef<HTMLDivElement>(null)

	const [height, setHeight] = useState<number | null>(null)

	const { isMobile: isTablet } = useIsMobile(1180)

	const [isOpenMenu, setIsOpenMenu] = useState(false)

	const location = useLocation()
	const search = useQueryParams()

	const intl = useIntl()

	const [dataTabMenu, setDataTabMenu] =
		useState<Array<LinkItemMenuProps>>(dataTab)

	usePageViews(dataTabMenu, setDataTabMenu)

	const { locale } = useAppSelector(state => state.defaultReducer)

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [location.pathname])

	useEffect(() => {
		if (ad_block) {
			const container = ref.current!

			const observer = new MutationObserver(() => {
				setHeight(container.clientHeight)
			})

			if (container) {
				observer.observe(container, { childList: true, subtree: true })
			}

			return () => {
				if (container) {
					observer.disconnect()
				}
			}
		}
	}, [])

	const tabName = search?.tab ? search?.tab + '_route' : null

	const navBreadCrumbs = useBreadCrumbs(dataTabMenu)

	useEffect(() => {
		if (titlePage) document.title = intl.formatMessage({ id: titlePage })
		if (descriptionMeta) {
			const dataText = intl.formatMessage({ id: descriptionMeta })
			document
				.querySelector('meta[name="description"]')!
				.setAttribute('content', dataText)
		}
	}, [locale, location, titlePage, descriptionMeta])

	return (
		<div
			id='App'
			className={clsx('App', styles.containerApp)}
			onMouseDown={event => {
				// закрытие под меню при за его пределами
				const openSubMenu = document.querySelector('.subMenu.active')
				if (openSubMenu && !openSubMenu.contains(event.target as Node)) {
					openSubMenu.classList.remove('active')
				}
			}}
		>
			<Suspense fallback={<Loader />}>
				<Header dataTabMenu={dataTabMenu} setDataTabMenu={setDataTabMenu} />
			</Suspense>
			{/* //TODO: Отключить чат и аналитику */}
			{/* <Chat /> */}
			<main className={styles.main}>
				{isTablet ? (
					<div className={styles.startLineInfoWrapper}>
						{location?.pathname !== '/' && (
							<>
								<div
									className={clsx(
										styles.startLineInfo,
										extraMenuBlock && styles.row,
										extraMenuBlock?.actionBlock && styles.left,
										(extraMenuBlock?.component || isCenterBreadcrumbs) &&
											styles.spaceBetween
									)}
								>
									{!extraMenuBlock && (
										<Breadcrumbs
											goBack={goBackLink}
											hiddenBreadcrumbsAll={hiddenBreadcrumbsAll}
											replaceRouteName={replaceRouteName}
											customBreadcrumbs={customBreadcrumbs}
										/>
									)}
									{extraMenuBlock && (
										<>
											<BreadcrumbsExtra
												isTab={!!tabName}
												isOpenMenu={isOpenMenu}
												setIsOpenMenu={setIsOpenMenu}
												navBreadCrumbs={navBreadCrumbs}
											/>
										</>
									)}
									{menuName && (
										<div
											className={clsx(
												styles.titleInfo,
												styles?.[titlePosition],
												!extraMenuBlock?.titleSection && styles.positionLeft
											)}
										>
											{messages[locale][
												// @ts-ignore
												isOpenMenu
													? extraMenuBlock?.titleSection
													: tabName || menuName
											] || menuName}
										</div>
									)}

									{isCenterBreadcrumbs && <div style={{ width: '22px' }} />}
									{!isOpenMenu && extraMenuBlock?.component && (
										<button
											className={styles.extraMenuButton}
											onClick={() => setIsOpenMenu(true)}
										>
											<img src={extraMenuBlock?.icon} alt={'button-action'} />
										</button>
									)}

									{extraMenuBlock?.actionBlock && extraMenuBlock.actionBlock}

									{isOpenMenu && (
										<>
											<button className={styles.extraMenuButton} />
											<div className={styles.content}>
												{extraMenuBlock?.component}
											</div>
										</>
									)}
								</div>
							</>
						)}
					</div>
				) : (
					<div className='AppMainBlock'>
						{!hiddenBreadcrumbs && (
							<Breadcrumbs
								idName={titlePage}
								hiddenQueryTab={hiddenQueryTab}
								replaceRouteName={replaceRouteName}
								customBreadcrumbs={customBreadcrumbs}
							/>
						)}
					</div>
				)}
				{!!head_block && (
					<div className='AppMainBlock' style={{ marginTop: 0 }}>
						{head_block}
					</div>
				)}
				{fullComponent && (
					<div className={styles.fullBlock}>{fullComponent}</div>
				)}
				<div
					className={clsx(
						`AppMainBlock`,
						mod.article && styles.articleWrapper,
						mod.container && styles.layout,
						mod.fullWidth && styles.fullWidth,
						mod.slots && styles.slotsWrapper,
						fullHeight && styles.fullHeight,
						fullWidth && 'fullDisplay'
					)}
					style={style}
				>
					<div
						className={clsx(
							'App-mainBlock',
							position,
							!ad_block && `fullWidth`,
							ad_block && styles.noneFull
						)}
					>
						<div
							className='content'
							ref={ref}
							// key={locale}
							style={{ display: 'flex', flexDirection: 'column' }}
						>
							{children}
						</div>
					</div>
					{ad_block && (
						<div
							style={{
								maxHeight: `${height}px`
							}}
							className={styles.adBlock}
						>
							{ad_block}
						</div>
					)}
				</div>

				<div>
					<ScrollButton />
				</div>
			</main>
			{!footerHidden && <Footer />}
		</div>
	)
}

export default Layout
