import cn, { clsx } from 'clsx'
import { FC } from 'react'

import styles from './EarnedDailyRewardModal.module.sass'
import {
	RewardSparkles,
	RewardSparklesMobile,
	rewardLeaves,
	rewardMoney,
	rewardMoneyMobile
} from '@/Assets/img/reward'
import { useIsMobile } from '@/shared/hooks'
import { useTranslationField } from '@/shared/hooks'
import { Button } from '@/shared/ui/form'

interface IProps {
	onClose: () => void
}

const EarnedDailyRewardModal: FC<IProps> = ({ onClose }) => {
	const { isMobile } = useIsMobile()
	const { getFieldStatic } = useTranslationField()

	const closeModal = () => {
		onClose()
	}

	return (
		<>
			<div className={styles.wrapper}>
				<div className={styles.content}>
					<h3
						dangerouslySetInnerHTML={{
							__html: getFieldStatic('rewardTitle')
						}}
					></h3>
					<div className={styles.reward}>
						<h3>+500 {getFieldStatic('betcoins')} </h3>
						<div className={styles.light} />
					</div>
					<div className={styles.button}>
						<Button modifiers={['gold']} onClick={closeModal}>
							OK
						</Button>
					</div>

					<div className={styles.rewardMoney}>
						{!isMobile ? (
							<>
								<RewardSparkles />
								<img src={rewardMoney} alt='reward money' />
							</>
						) : (
							<>
								<RewardSparklesMobile />
								<img src={rewardMoneyMobile} alt='reward money' />
							</>
						)}
					</div>
				</div>

				<div className={styles.rewardLeaves}>
					<img
						className={clsx(isMobile && styles.none)}
						src={rewardLeaves}
						alt='leaves'
					/>
				</div>
			</div>

			<div
				tabIndex={-1}
				role='switch'
				aria-checked='false'
				id='backdrop'
				onKeyDown={() => {}}
				className={styles.backdrop}
			/>
		</>
	)
}

export default EarnedDailyRewardModal
