import clsx from 'clsx'
import { FC } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import styles from './Breadcrumbs.module.scss'
import { getPrevUrlLink } from './helpers'
import { messages } from '@/i18n/messages'
import { ArrowBack } from '@/shared/assets/common/svg'
import { useIsMobile, useTranslationField } from '@/shared/hooks'
import { TValuesLocality } from '@/shared/types/global.types'
import { useAppSelector } from '@/shared/model'

interface IProps {
	replaceRouteName?: [string | RegExp, string]
	hiddenQueryTab?: boolean
	goBack?: boolean
	hiddenBreadcrumbsAll?: boolean
	idName?: string
	customBreadcrumbs?: {
		label: string
		path: string
	}[]
}

const Breadcrumbs: FC<IProps> = ({
	replaceRouteName,
	hiddenQueryTab,
	goBack,
	hiddenBreadcrumbsAll,
	idName,
	customBreadcrumbs
}) => {
	const { getFieldStatic } = useTranslationField()

	const { locale } = useAppSelector(state => state.defaultReducer)
	const navigate = useNavigate()
	const { isMobile: isTablet } = useIsMobile(1180)

	const location = useLocation()
	let currentLink = ''

	const pathnameCrumbs = location.pathname
		.split('/')
		.filter(crumb => crumb !== '')

	const searchParamsCrumbs = location.search
		.slice(1)
		.split('&')
		.map(crumb => crumb.split('='))
		.filter(crumb => {
			const arr = hiddenQueryTab ? ['', 'tab'] : ['']
			return !arr.includes(crumb[0])
		})

	const crumbs =
		pathnameCrumbs.concat(searchParamsCrumbs.map(param => param[1])) || []

	const messagesLocale = messages[locale]

	const routeName = `${crumbs.at(-1)}_route` as keyof typeof messagesLocale

	const goBackLink = () => {
		const link = getPrevUrlLink(searchParamsCrumbs, location)
		if (goBack) return navigate(-1)
		navigate(link)
	}

	const finalRouteName = replaceRouteName
		? routeName.replace(replaceRouteName[0], replaceRouteName[1])
		: routeName

	if (isTablet) {
		return (
			<>
				<div onClick={goBackLink} className={styles.backPath}>
					<ArrowBack />
				</div>
				{!hiddenBreadcrumbsAll && (
					<>
						{customBreadcrumbs ? (
							<span>{customBreadcrumbs.at(-1)?.label}</span>
						) : (
							<span>{getFieldStatic(finalRouteName as TValuesLocality)}</span>
						)}
					</>
				)}
			</>
		)
	}
	return (
		<div className={styles.breadcrumbs}>
			<div className={styles.crumb}>
				<Link to={'/'}>{getFieldStatic('home_route')}</Link>
				<span>/</span>
			</div>
			{customBreadcrumbs ? (
				<>
					{customBreadcrumbs.map((item, index) => (
						<div className={styles.crumb} key={item.label}>
							<Link
								className={clsx(
									index === crumbs.length - 1 && styles.lastCrumb
								)}
								to={item.path}
							>
								{item.label}
							</Link>
							{index === customBreadcrumbs.length - 1
								? ''
								: item.label !== 'item'
								? '/'
								: ''}
						</div>
					))}
				</>
			) : (
				<>
					{crumbs.map((crumb, index) => {
						const searchParam = searchParamsCrumbs.find(
							param => param[1] === crumb
						)

						if (searchParam) {
							if (currentLink.includes('?')) {
								if (
									!currentLink.includes(`${searchParam[0]}=${searchParam[1]}`)
								) {
									currentLink += `&${searchParam[0]}=${searchParam[1]}`
								}
							} else {
								currentLink += `?${searchParam[0]}=${searchParam[1]}`
							}
						} else currentLink += `/${crumb}`

						if (!isNaN(Number.parseInt(crumb)) || searchParam) {
							return null
						}

						const isNameRoute =
							idName && crumb === 'item' ? idName : String(crumb) + '_route'

						const finalRouteName = replaceRouteName
							? isNameRoute.replace(replaceRouteName[0], replaceRouteName[1])
							: isNameRoute

						return (
							<div className={styles.crumb} key={crumb}>
								<Link
									className={clsx(
										index === crumbs.length - 1 && styles.lastCrumb
									)}
									to={
										isNaN(Number.parseInt(crumbs[index + 1]))
											? currentLink
											: `${currentLink}/${crumbs[index + 1]}`
									}
								>
									{getFieldStatic(finalRouteName as TValuesLocality)}
								</Link>
								{index === crumbs.length - 1 ? '' : crumb !== 'item' ? '/' : ''}

								{isTablet &&
									(index !== crumbs.length - 2 && crumb !== 'item' ? '' : '/')}
								{index !== crumbs.length - 2 && <span></span>}
							</div>
						)
					})}
				</>
			)}
		</div>
	)
}

export default Breadcrumbs
